import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import FriendCircleStoreType,{ upDataType,ItemType } from "./indexType"

@Module({
    name:"FriendCircle",
    store,
    dynamic: true,
})
export default class FriendCircle extends VuexModule implements FriendCircleStoreType{
    navState = 0;
    get getNavState(){
        return this.navState
    }
    @Mutation
    setNavState(state: number): void {
        this.navState = state
    }

    upDataCircle:upDataType = { pageNo: 1, pageSize: 15,queryAuth:0 };
    get getUpDataCircle(){
        return this.upDataCircle
    }
    @Mutation
    setUpDataCircle(data:upDataType){
        this.upDataCircle = data
    }

    listCircle: ItemType[] = [];
    get getListCircle(){
        return this.listCircle
    }
    @Mutation
    setListCircle(data: ItemType[]): void {
        this.listCircle = data
    }


}

export const FriendCircleStore = getModule( FriendCircle )
