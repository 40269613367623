import {findFriend, friendGive} from "@/Api/Friend/index";
import Storage from "@/util/Storage"
import { FriendCircleStore } from "./Store/index"

/** 查询朋友圈 */
export function getFriends() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( findFriend({ userId,token },FriendCircleStore.getUpDataCircle).then(res=>{
        return res.data
    }) )
}


/** 点赞 */
export function getGive(id: string|number ) {
    let body = { momentsId:id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendGive({ userId,token },body).then(res=>{
        return res.data
    }))
}

