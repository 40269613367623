

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import FriendSuccessType, {commentType, updateType} from "@/views/Friend/FriendSuccess/Store/indexType";

@Module({
    name:"FriendSuccess",
    store,
    dynamic: true,
})
export default class FriendSuccess extends VuexModule implements FriendSuccessType{

    commentShow = false;
    get getCommentShow(){ return this.commentShow }
    @Mutation
    setCommentShow(show: boolean): void {
        this.commentShow = show
    }

    commentData: commentType = {
        secondComment:false,
        momentsId:"",
        toId:"",
        content:""
    };
    get getCommentData(){ return this.commentData }
    @Mutation
    setCommentData(data: commentType): void {
        this.commentData = data
    }

    upDataSuc: updateType = {
        pageNo:1,
        pageSize:15,
        momentsId:""
    };
    get getUpDataSuc(){ return this.upDataSuc }
    @Mutation
    setUpDataSuc(data: updateType): void {
        this.upDataSuc = data
    }

    ListSuc: commentType[] = [];
    get getList(){ return this.ListSuc }
    @Mutation
    setListSuc(data: commentType[]): void {
        this.ListSuc = data
    }

    myAdminShow = false;
    get getMyAdminShow(){ return this.myAdminShow }
    @Mutation
    setMyAdminShow(show: boolean): void {
        this.myAdminShow = show
    }

}

export const FriendSuccessStore = getModule( FriendSuccess )
